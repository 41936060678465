// CONSTANTS

$primary-color: #545cd8;
$secondary-color: #ffb6c1;

//DOCPROCESS

.users-list {
    .list-group-item {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        margin-bottom: 2px;

        span {
            font-weight: bold;
            font-size: 0.9rem;
            font-family: 'Alegreya', serif;
        }

        &:hover {
            background-color: #FFF3B2
        }
    }
}

//BROKERS

.outer-border {
    padding: 20px;
    background-color: #fff;
    box-shadow: 12px 12px 5px grey;
    border-radius: 15px;
    width: 100%;

    form {
        border-radius: 15px;
        background-color: #fff;
        padding: 15px;
        z-index: 1;
        position: relative;
        box-shadow: 5px 5px 5px 5px grey;

        .col-form-label {
            text-transform: uppercase;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }

        .form-control-file {
            visibility: hidden;
        }

        #priceOfferFileLabel {
            background-color: $primary-color;
            margin-bottom: 0;
            cursor: pointer;
            text-transform: uppercase;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            border-radius: 4rem;
            color: #fff;
            font-size: 1rem;
            display: inline-block;
            height: 3rem;
            font-weight: 700;
            line-height: 3rem;
            padding-left: 2rem;
            padding-right: 2rem;
            transition: background-color .3s;

            &:hover {
                background-color: coral;
            }
        }

        .btn {
            text-transform: uppercase;
            margin-top: 8px;
            transition: background-color .5s;

            &:hover {
                background-color: coral;
                border: none;
            }
        }
    }
}

//SMS

.wizard-steps {
    .col-sm-2 {
        text-align: center;
    }

    span {
        color: red
    }

    .message-form {
        background-color: #add8e6;
        padding: 10px;
        border-radius: 10px;
    }

    .row-option-selection {
        .row {
            margin: auto;

            .custom-checkbox {
                margin-right: 30px;
            }
        }

        input[type=text],
        input[type=number] {
            border: none;
            border-bottom: 1px solid black;
            width: 100px;
            font-size: 12px;
            background-color: transparent;
        }
    }

    .fieldset {
        border: 3px solid $primary-color;
        padding: 15px;
        border-radius: 15px;

        h5 {
            font-family: "Bebas Neue", cursive;
        }
    }

}

// ALL 
.div__space-between {
    display: flex;
    justify-content: space-between;
}

.centered {
    text-align: center;
}